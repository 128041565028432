@import "assets/styles/variables";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "Open-Sans";
  src: url("./assets/fonts/OpenSans-VariableFont.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Liberation-Sans";
  src: url("./assets/fonts/LiberationSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Liberation-Sans";
  src: url("./assets/fonts/LiberationSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Futura BdCn BT";
  src: url("./assets/fonts/futuraboldcondensedbt.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Futura BdCn BT";
  src: url("./assets/fonts/futuraboldcondensedbt.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/////////resolves scroll bug in ios///////////////
html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
  overflow: auto;
}
@media print {
  html {
    height: auto;
    overflow: visible;
  }
  body {
    height: auto;
    overflow: visible;
  }
}
/////////////////////////////////////////
.footer-background {
  background-color: var(--footer-background);
}

.network-table-container {
  max-height: calc(
    100vh - (#{var(--header-height-desktop)} + #{var(--footer-height)} + 40px)
  );
  @media (max-width: $max-width-tablet-portrait) {
    max-height: calc(
      100vh - (#{var(--header-height-mobile)} + #{var(--footer-height)} + 40px)
    );
  }
}

.appbar-button {
  & > div {
    color: var(--text-color);
    font-size: 14px;
  }
  & > span > div {
    color: var(--text-color);
  }
  & > span {
    color: var(--text-color);
  }
}
//to fix
.table-pagination {
  & > div {
    color: var(--text-color-secondary);
    padding-right: none;
    & > :nth-child(5) {
      margin-left: 0px;
    }
  }
  & > div > div:nth-child(3) {
    & > svg {
      color: var(--text-color-secondary);
    }
  }

  & > div > div > button > svg {
    color: var(--text-color);
  }

  & > div > div > button:disabled > svg {
    color: var(--text-color-disabled);
  }
}

@media (max-width: $max-width-small-mobile) {
  .table-pagination {
    & > div {
      padding-left: 0px;
    }
    & > div > div:nth-child(3) {
      margin-right: 5px;
      margin-left: 0px;
    }
    & > div > div:nth-child(5) {
      margin-right: 0px;
      margin-left: 0px;
      & > button {
        padding: 0px;
      }
    }
    & > div > div > button > svg {
      color: var(--text-color);
    }

    & > div > div > button:disabled > svg {
      color: var(--text-color-disabled);
    }
  }
}

.form-label {
  & > label {
    color: var(--coupon-text-color) !important; //changes label color
    text-transform: capitalize;
    z-index: 0;
  }
  & > label > span {
    color: var(--coupon-text-color);
    text-transform: capitalize;
  }
  & > div > div {
    color: var(--coupon-text-color);
  }
  & > div > div > button {
    color: var(--coupon-text-color) !important;
  }
  /*input color of textfield as select*/
  & > div > div > span > span {
    color: var(--text-color);
  }

  /*input color of simple textfield*/
  & > div > input {
    color: var(
      --coupon-text-color
    ) !important; //changes  text color inside input
    font-family: var(--secondary-font-family) !important;
  }
  //changes  text color inside input
  & > div > input:disabled {
    color: var(--text-color-disabled) !important;
    -webkit-text-fill-color: var(--text-color-disabled) !important;
  }
  & > div > fieldset {
    color: var(--coupon-text-color) !important;
    border: var(--primary-border-color); //changes borders color for outlined
    border-radius: var(--border-radius-small);
  }

  & > div > fieldset:disabled {
    color: var(--text-color-disabled) !important;
    border: 1px solid var(--text-color-disabled) !important; //changes borders color for outlined
  }
  & > div > textarea {
    color: var(--coupon-text-color) !important;
  }
  & > div > svg {
    color: var(--coupon-text-color) !important; // svg icon for textfield
  }
  & > div > div > button > svg {
    color: var(--coupon-text-color) !important; //svg icon for autoselect
  }
}

.radio-button {
  color: var(--coupon-text-color);
}

.radio-group {
  display: flex;
  color: var(--coupon-text-color) !important;
}
//to fix
// remove tab active indicator from these sliders
.remove-active-indicator {
  .MuiTabs-indicator {
    display: none !important;
  }
}
.sportBarItem,
.categoryBarItem {
  > .Mui-disabled {
    display: none !important;
  }
}
/** global styles for carousel */

.alice-carousel__prev-btn {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 80%;
  width: 1% !important;
  z-index: 0 !important;
}

.alice-carousel__next-btn {
  position: absolute;
  top: 0%;
  right: 0%;
  height: 80%;
  width: 1% !important;
  z-index: 0 !important;
}

.casinoMainBannerCarousel {
  & > div {
    & img {
      width: 100% !important;
    }
  }
}
//home carousel
.homeSlickSlider {
  margin-bottom: 30px;

  & > div > div {
    display: flex;
    gap: 5px;
  }
  .slick-dots {
    li {
      &.slick-active {
        button:before {
          color: var(--main-color);
        }
      }
      button:before {
        color: var(--sport-select-background-color);
      }
    }
  }
}
/****** mui classes (global classes,ask before any change ******/
.MuiTableCell-root {
  border-bottom: var(--table-border-color) !important;
}
.MuiTableCell-root {
  border-right: var(--table-border-color) !important;
}

/** recursive tree override*/
.MuiTreeItem-group {
  margin-left: 10px !important;
}
.MuiTreeItem-iconContainer {
  display: none !important;
}

.MuiTreeItem-content {
  padding: 0px !important;
  border-radius: var(--border-radius-small);
  background-color: var(--background-color) !important;
  margin: 3px 0px;

  @media (min-width: $max-width-tablet) {
    &:hover {
      background-color: none !important;
    }
  }
}
.MuiTreeItem-label {
  padding-left: 0px !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 4px 0px !important;
}

// .MuiInputBase-root.Mui-disabled {
//   color: red !important;
// }
.MuiDialogActions-root {
  color: "#ffff";
  background-color: "#ffff";
}

.slick-slider .slick-list {
  @media (max-width: $max-width-tablet) and (min-width: $max-width-tablet-portrait) {
    height: 100%;
    & > div {
      height: 100%;

      & > div {
        & > div {
          height: 100%;
        }
      }
    }
  }
}

.css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  display: none !important;
}
